import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'action-button',
  templateUrl: './action-button.component.html',
  styleUrls: ['./action-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionButtonComponent {

  @Output() action: EventEmitter<void> = new EventEmitter<void>();

  @Input() text = '';
  @Input() type: 'filled' | 'outline' = 'filled';
  @Input() routerLink = null;
  @Input() disabled = false;
  @Input() iconLeft = null;
  @Input() iconRight = null;

  constructor(private router: Router) {}

  click_Action() {
    if (!this.disabled) {
      if (this.routerLink) {
        this.router.navigate([this.routerLink]);
      } else {
        this.action.emit();
      }
    }
  }

}
