export interface ITheme {
    name: string;
    properties: IThemeProps;
}

export interface IThemeProps {
    // # Main
    'primary': string; // Main Brand Colour - e.g. Blue
    'primary-alt': string; // Main Brand Colour but slightly different for gradiant - e.g. Darker Blue
    'primary-contrast': string; // Text or Icons on ,main colour - e.g. White

    'accent': string; // Colour for the select boxs - e.g. Light Blue
    'accent-alt': string; // Colour for the select boxs - e.g. Light Blue
    'accent-contrast': string; // Contrast for accent colour - e.g. White

    'background': string; // Navigation Bar Background - e.g. White on main, Blue on player
    'background-alt': string; // Navigation Bar but slightly different for gradiant - e.g. White on main, Darker Blue on player
    'background-contrast': string; // Text on the Navigation Bar - e.g. Black on main, White on player

    // # Navigation
    'nav-background': string; // Navigation Bar Background - e.g. White on main, Blue on player
    'nav-background-alt': string; // Navigation Bar but slightly different for gradiant - e.g. White on main, Darker Blue on player
    'nav-background-contrast': string; // Text on the Navigation Bar - e.g. Black on main, White on player
    'background-contrast-alt': string,

    'error': string;
    'warning': string;
    'good': string;
}

export const LightTheme: ITheme = {
    name: 'Light Default',
    properties: {
        'primary': '#f95f96',
        'primary-alt': '#fa9c8e',
        'primary-contrast': '#ffffff',

        'accent': '#12e3ac',
        'accent-alt': '#fa9c8e',
        'accent-contrast': '#ffffff',

        'background': '#ffffff',
        'background-alt': '#cecece',
        'background-contrast': '#000000',
        'background-contrast-alt': '#8b8b8b',

        'nav-background': '#ffffff',
        'nav-background-alt': '#ffffff',
        'nav-background-contrast': '#000000',

        'error': '#f95f95',
        'warning': '#ffba88',
        'good': '#bdcf92',
    }
};
