import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageComponent } from './pages/home/home.page';
import { AboutPageComponent } from './pages/about/about.page';
import { ContactPageComponent } from './pages/contact/contact.page';
import { LegalPageModule } from './pages/legal/legal.page.module';
import { LegalPageComponent } from './pages/legal/legal.page';
import { TermsPageComponent } from './pages/terms/terms.page';
import { PortfolioPageComponent } from './pages/portfolio/portfolio.page';
import { PortfolioItemPageComponent } from './pages/portfolio/portfolio copy/portfolio-item.page';


const routes: Routes = [
  {
    path: '',
    component: HomePageComponent,
    data: {
      navSection: 'Home',
      pageTitle: 'Home'
    }
  },
  {
    path: 'about',
    component: AboutPageComponent,
    data: {
      navSection: 'About',
      pageTitle: 'About'
    }
  },
  {
    path: 'portfolio',
    component: PortfolioPageComponent,
    data: {
      navSection: 'Portfolio',
      pageTitle: 'Portfolio'
    }
  },
  {
    path: 'portfolio/:id',
    component: PortfolioItemPageComponent,
    data: {
      navSection: '',
      pageTitle: 'Portfolio'
    }
  },
  {
    path: 'press',
    component: PortfolioItemPageComponent,
    data: {
      navSection: '',
      pageTitle: 'Presskit'
    }
  },
  {
    path: 'contact',
    component: ContactPageComponent,
    data: {
      navSection: 'Contact',
      pageTitle: 'Contact'
    }
  },
  {
    path: 'privacy-policy',
    component: LegalPageComponent,
    data: {
      navSection: '',
      pageTitle: 'Privacy Policy'
    }
  },
  {
    path: 'terms-and-conditions',
    component: TermsPageComponent,
    data: {
      navSection: '',
      pageTitle: 'T&C\'s'
    }
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
