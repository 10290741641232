import { Component, OnInit } from '@angular/core';
import { NavService } from 'src/app/services/nav.service';
import { Router, NavigationEnd, Event, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  private $routerEvents: Subscription;

  public isOpaque = true;

  public mobileMenuOpen = false;
  public pageSection = '';
  public pageName = '';

  public navbarOptions: { label: string, link: string }[] = [
    {
      label: 'Home',
      link: '/'
    },
    {
      label: 'About',
      link: '/about'
    },
    {
      label: 'Portfolio',
      link: '/portfolio'
    },
    // {
    //   label: 'Presskit',
    //   link: '/presskit'
    // },
    {
      label: 'Contact',
      link: '/contact'
    }
  ];
  public navbarSocials: { icon: string, link: string }[] = [
    {
      icon: 'Instagram',
      link: 'https://www.instagram.com/versionmaybe/'
    },
    {
      icon: 'Facebook',
      link: 'https://www.facebook.com/versionmaybe/'
    },
    {
      icon: 'Dribbble',
      link: 'https://dribbble.com/versionmaybe'
    }
  ];

  constructor(
    private navService: NavService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.$routerEvents = this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.pageSection = this.route.snapshot.firstChild.data.navSection;
        this.pageName = this.route.snapshot.firstChild.data.pageTitle;
        this.mobileMenuOpen = false;
      }
  });
  }

  ngOnInit() {
  }

  toggleMenu() {
    this.mobileMenuOpen = !this.mobileMenuOpen;
  }

}
