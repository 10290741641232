import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PortfolioPageComponent } from './portfolio.page';
import { FooterModule } from 'src/app/components/blocks/footer/footer.module';
import { SimpleBlockModule } from 'src/app/components/blocks/simple-block/simple-block.module';
import { HeroTextModule } from 'src/app/components/blocks/hero-text/hero-text.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { SideImageModule } from 'src/app/components/blocks/side-image/side-image.module';
import { ImageSliderModule } from 'src/app/components/blocks/image-slider/image-slider.module';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [
    PortfolioPageComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FooterModule,
    SimpleBlockModule,
    HeroTextModule,
    SideImageModule,
    ImageSliderModule,
    RouterModule
  ],
  exports: [
    PortfolioPageComponent,
  ],
})
export class PortfolioPageModule { }
