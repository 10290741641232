import { Component, OnInit } from '@angular/core';
import { ThemeService } from './services/theme.service';
import { RouterOutlet, Router, NavigationEnd } from '@angular/router';
import { swipeAnimation } from './animations';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    swipeAnimation
  ]
})
export class AppComponent implements OnInit {

  scrollAnimation: any;

  constructor(
    private themeService: ThemeService,
    private router: Router
  ) {}

  prepareRoute(outlet: RouterOutlet) {
    return outlet.activatedRouteData;
  }

  ngOnInit() {
  }

  onActivate(event) {
    // this.scrollPlease();
    setTimeout(() => {
      document.querySelector('.o-app-wrapper__page').scrollTop = 0;
    }, 250);
    //   top: 0, // could be negative value
    //   left: 0,
    //   behavior: 'smooth'
    // });
    // this.scrollToTop();
  }

  scrollPlease() {
    const pos = document.querySelector('.o-app-wrapper__page').scrollTop;
    if (pos > 0) {
      document.querySelector('.o-app-wrapper__page').scrollTop = pos - 150;
      // console.log(pos);
      setTimeout(() => {
        this.scrollPlease();
      }, 0);
    }
  }

  scrollToTop() {
    const ele = document.querySelector('.o-app-wrapper__page');
    const position = ele.scrollTop;
    if (position) {
        window.scrollBy(0, -Math.max(1, Math.floor(position / 10)));
        this.scrollAnimation = setTimeout(() => this.scrollToTop, 30);
    } else clearTimeout(this.scrollAnimation);
}
}
