import { Component, OnInit, Input, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-image-slider',
  templateUrl: './image-slider.component.html',
  styleUrls: ['./image-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class ImageSliderComponent implements OnInit, OnDestroy {

  @Input() title = '';
  @Input() images: string[] = [];
  @Input() links: string[] = null;

  public currentImage = 0;
  @Input() speed = 5000;
  private intervalID = null;

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
    this.intervalID = setInterval(() => this.nextImage(), this.speed);
  }

  ngOnDestroy() {
    clearInterval(this.intervalID);
  }

  nextImage() {
    this.currentImage ++;
    if (this.currentImage >= this.images.length) {
      this.currentImage = 0;
    }
  }

  gotoLink(url: string) {
    if (url.includes('http')) {
      window.open(url);
    } else {
      this.router.navigate([url]);
    }
  }

}
