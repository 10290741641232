import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { ThemeService } from './services/theme.service';
import { NavbarModule } from './components/navbar/navbar.module';
import { HomePageModule } from './pages/home/home.page.module';
import { IconicModule } from './components/iconic/iconic.module';
import { AboutPageModule } from './pages/about/about.module';
import { FooterComponent } from './components/blocks/footer/footer.component';
import { FooterModule } from './components/blocks/footer/footer.module';
import { ActionButtonModule } from './components/action-button/action-button.module';
import { HeroTextModule } from './components/blocks/hero-text/hero-text.module';
import { SideImageModule } from './components/blocks/side-image/side-image.module';
import { ImageSliderModule } from './components/blocks/image-slider/image-slider.module';
import { IsVisibleDirective } from './directives/is-visible.directive';
import { VisibleFadeDirective } from './directives/visible-fade.directive';
import { SharedModule } from './shared/shared.module';
import { ContactPageModule } from './pages/contact/contact.page.module';
import { SimpleBlockModule } from './components/blocks/simple-block/simple-block.module';
import { LegalPageModule } from './pages/legal/legal.page.module';
import { TermsPageModule } from './pages/terms/terms.page.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { PortfolioPageModule } from './pages/portfolio/portfolio.module';
import { PortfolioItemPageModule } from './pages/portfolio/portfolio copy/portfolio-item.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    IconicModule,
    HttpClientModule,
    FormsModule,

    HomePageModule,
    AboutPageModule,
    PortfolioPageModule,
    PortfolioItemPageModule,
    ContactPageModule,
    LegalPageModule,
    TermsPageModule,

    NavbarModule,
    FooterModule,

    ActionButtonModule,
    HeroTextModule,
    SideImageModule,
    ImageSliderModule,
    SharedModule,
    SimpleBlockModule
  ],
  providers: [
    ThemeService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
