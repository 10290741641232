import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { ITheme, LightTheme } from '../interfaces/ITheme';

@Injectable({
    providedIn: 'root'
})

export class ThemeService {
    private currentTheme: ITheme = null;
    private cssElement: HTMLStyleElement = null;

    constructor() {
        //  If we dont have theming elements then create them.
        if (!this.cssElement) {
            const styleStatic = document.createElement('style');
            styleStatic.id = 'Theme';
            this.cssElement = styleStatic;
            document.head.appendChild(styleStatic);
        }

        if (this.currentTheme === null) {
            this.setCurrentTheme(LightTheme);
        }
    }

    /**
     * Get the currently active theme.
     */
    getCurrentTheme(): ITheme {
        return this.currentTheme;
    }

    /**
     * Set the current theme.
     * @param wantedTheme The theme object wanted to make active.
     */
    setCurrentTheme(wantedTheme: ITheme, wantedThemeState = 'main'): void {
        this.currentTheme = wantedTheme;
        this.setDocumentStyling(this.cssElement, this.currentTheme.properties);
    }

    /**
     * Set styles in the document :root to be used in styling.
     * @param styleSheet the stylesheet to apply the elements to.
     * @param properties object with the names and values for each style.
     */
    private setDocumentStyling(styleSheet: HTMLStyleElement, properties: any) {
        let styleRules = '';
        console.log('[Theme] Applied New Theme');
        Object.keys(properties).forEach(element => {
         styleRules += '--theme-' + element + ': ' + properties[element] + ';\n';
        });

        const cssSheet = (styleSheet.sheet as CSSStyleSheet);
        if (cssSheet.cssRules.length > 0) {
            cssSheet.deleteRule(0);
        }
        cssSheet.insertRule(':root {' + styleRules + '}');
    }
}
