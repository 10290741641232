import { Component, OnInit, Input, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-versionmaybe',
  templateUrl: './versionmaybe.component.html',
  styleUrls: ['./versionmaybe.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VersionmaybeComponent implements OnInit {

  @ViewChild('vmLogo', null) vmLogoElement: ElementRef;

  @Input() set version(val: string) { this.vmLogoElement.nativeElement.style.setProperty('--vm-logo-version', val); }
  @Input() set maybe(val: string) { this.vmLogoElement.nativeElement.style.setProperty('--vm-logo-maybe-1', val); }
  @Input() set maybeAlt(val: string) { this.vmLogoElement.nativeElement.style.setProperty('--vm-logo-maybe-2', val); }

  public gradiantName = 'linear-gradiant';

  constructor() {
    this.gradiantName += Math.random().toPrecision(4);
   }

  ngOnInit() {
  }

}
