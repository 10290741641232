import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomePageComponent } from './home.page';
import { FooterModule } from 'src/app/components/blocks/footer/footer.module';
import { HeroTextModule } from 'src/app/components/blocks/hero-text/hero-text.module';
import { SideImageModule } from 'src/app/components/blocks/side-image/side-image.module';
import { ImageSliderModule } from 'src/app/components/blocks/image-slider/image-slider.module';
import { SharedModule } from 'src/app/shared/shared.module';



@NgModule({
  declarations: [
    HomePageComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FooterModule,
    HeroTextModule,
    SideImageModule,
    ImageSliderModule,
  ],
  exports: [
    HomePageComponent,
  ],
})
export class HomePageModule { }
