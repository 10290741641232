import { NgModule } from '@angular/core';
import { IconicComponent } from './/iconic.component';

@NgModule({
    declarations: [
        IconicComponent,
    ],
    imports: [],
    exports: [
        IconicComponent,
    ],
})
export class IconicModule {}
