import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
// import { Subscription } from 'rxjs/internal/Subscription';

@Injectable({
    providedIn: 'root'
})

export class NavService {

    constructor(
        private router: Router,
        private http: HttpClient
    ) {
    }

    public go(link: string) {
        this.router.navigate([link]);
    }

    public sendEmail(sender: string, displayName: string, message: string) {
        return this.http.post('https://versionmaybe.com/api/web/contact_form.php', {
            form_sender: sender,
            form_name: displayName,
            form_msg: message
        });
    }
}
