import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.page.html',
  styleUrls: ['./about.page.scss']
})
export class AboutPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
