import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeroTextComponent } from './hero-text.component';
import { ActionButtonModule } from '../../action-button/action-button.module';
import { SharedModule } from 'src/app/shared/shared.module';



@NgModule({
  declarations: [
    HeroTextComponent
  ],
  imports: [
    CommonModule,
    ActionButtonModule,
    SharedModule
  ],
  exports: [
    HeroTextComponent
  ],
})
export class HeroTextModule { }
