import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './navbar.component';
import { VersionMaybeModule } from '../versionmaybe/versionmaybe.module';
import { IconicModule } from '../iconic/iconic.module';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [
    NavbarComponent
  ],
  imports: [
    CommonModule,
    VersionMaybeModule,
    IconicModule,
    RouterModule
  ],
  exports: [
    NavbarComponent,
  ],
})
export class NavbarModule { }
