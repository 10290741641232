import { Component, OnInit, Input, ViewEncapsulation, ElementRef, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { IconicIcon, IconicIconList } from './iconic.constants';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'iconic-icon',
  styleUrls: ['./iconic.component.scss'],
  encapsulation: ViewEncapsulation.None,
  template:
  `<svg
    #icon
    class="c-iconic-icon"
    viewBox="0 0 20 20" height="100%" width="100%"
    [innerHTML]="currentIconSVG">
    </svg>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconicComponent {

  @ViewChild('icon', null) iconElement: ElementRef;
  currentIcon: any = null;
  currentIconSVG: SafeHtml = null;

  @Input() type = 'default';
  @Input() set color(val: string) { this.iconElement.nativeElement.style.setProperty('--iconic-color', val); }
  // When an input is set we want to check if it's a constant from the icon list
  // or if it is an icon name.
  @Input() set icon(val: IconicIcon | string) {
    if ( typeof val !== 'string' ) {
      // If it is a constant then we'll just set it straight away.
      this.currentIcon = this.getIconType(val as IconicIcon);
    } else {
      // If it is an icons name then we'll quickly find the associated icon.
      const found = Object.keys(IconicIconList).find(x => IconicIconList[x].name.toLowerCase() === val.toLowerCase());
      if (found) {
        // If an icon is found then set it.
        this.currentIcon = IconicIconList[found];
      } else {
        // If not then quickly post a warning into the console.
        console.warn(`[Iconic] Icon '${val}' not available.`);
      }
    }

    // Lastly we need to sanitise / trust the SVG html we are going to inject into the component/
    if (this.currentIcon) {
      this.currentIconSVG = this.sanitized.bypassSecurityTrustHtml(this.getIconType(this.currentIcon).svg);
    }
  }

  constructor(private sanitized: DomSanitizer) {}

  private getIconType(wantedIcon: IconicIcon): any {
    return wantedIcon.icon.find((x) => x.type === this.type);
  }

}
