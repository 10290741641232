import {
    trigger,
    transition,
    style,
    query,
    group,
    animate,
    animateChild,
    keyframes
} from '@angular/animations';

export const swipeAnimation =
    trigger('routeAnimations', [
        transition('* <=> *', [
            // Setup
            query(':enter, :leave', [
                style({
                    position: 'absolute',
                    // overflow: 'auto',
                    width: '100%',
                    height: '100%',
                    transform: 'scale(1) translateY(0)',
                })
            ], { optional: true }),
            // Page Leaving
            query(':leave', [
                style({
                    // overflow: 'hidden',
                    // position: 'relative',
                    // zIndex: '10',
                    transform: 'scale(1) translateY(0)',
                    opacity: 1
                })
            ], { optional: true }),
            // Page Entering
            query(':enter', [
                style({
                    position: 'absolute',
                    zIndex: '10',
                    overflow: 'auto',
                    transform: 'scale(1) translateY(-2vh)',
                    opacity: 0
                })
            ], { optional: true }),

            // ## Transitions
            query(':leave', [
                animate('0.2s ease', style({
                    transform: 'scale(1) translateY(-1vh)',
                    opacity: 0
                }))
            ], { optional: true }),
            query(':enter', [
                animate('0.35s ease', style({
                    transform: 'scale(1) translateY(0rem)',
                    opacity: 1
                }))
            ], { optional: true }),
        ])
    ]);

