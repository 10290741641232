export interface IconicIcon {
    name: string;
    icon: {
        type: string,
        svg: string,
    }[];
}

export const IconicIconList = {
    Plus: {
        name: 'Plus',
        icon: [
            {
                type: 'default',
                svg: `<path class="IconicIcon" d="M17.37,9.37H10.63V2.63A.63.63,0,0,0,10,2a.63.63,0,0,0-.63.63V9.37H2.63A.63.63,0,0,0,2,10a.64.64,0,0,0,.63.63H9.38v6.74A.63.63,0,0,0,10,18a.63.63,0,0,0,.63-.63V10.63h6.74A.63.63,0,0,0,18,10,.65.65,0,0,0,17.37,9.37Z"/>`
            }
        ]
    } as IconicIcon,
    Cross: {
        name: 'Cross',
        icon: [
            {
                type: 'default',
                svg: `<path class="IconicIcon" d="M15.66,14.76,10.89,10l4.77-4.77a.63.63,0,0,0-.89-.89L10,9.1,5.23,4.33a.63.63,0,0,0-.88,0,.64.64,0,0,0,0,.89L9.12,10,4.35,14.77a.63.63,0,0,0,.89.89L10,10.89l4.77,4.77a.64.64,0,0,0,.89,0A.65.65,0,0,0,15.66,14.76Z"/>`
            }
        ]
    } as IconicIcon,
    Menu: {
        name: 'Menu',
        icon: [
            {
                type: 'default',
                svg: `  <g>
                            <path class="IconicIcon" d="M17.37,9.42H2.63a.63.63,0,1,0,0,1.25H17.37a.63.63,0,1,0,0-1.25Z"/>
                            <path class="IconicIcon" d="M2.63,4.49H17.37A.63.63,0,0,0,18,3.86a.63.63,0,0,0-.63-.63H2.63A.63.63,0,0,0,2,3.86.63.63,0,0,0,2.63,4.49Z"/>
                            <path class="IconicIcon" d="M17.37,15.51H2.63a.63.63,0,0,0-.63.63.63.63,0,0,0,.63.63H17.37a.63.63,0,0,0,.63-.63A.63.63,0,0,0,17.37,15.51Z"/>
                        </g>`
            }
        ]
    } as IconicIcon,
    ArrowDown: {
        name: 'Arrow Down',
        icon: [
            {
                type: 'default',
                svg: `<path class="IconicIcon" d="M2,6.37a.72.72,0,0,1,.21-.52.76.76,0,0,1,1,0L10,12.58l6.73-6.73a.76.76,0,0,1,1.05,0,.74.74,0,0,1,0,1.05l-7.27,7.27a.75.75,0,0,1-.51.19.69.69,0,0,1-.52-.21L2.21,6.9A.79.79,0,0,1,2,6.37Z"/>`
        }]
    } as IconicIcon,
    ArrowRight: {
        name: 'Arrow Right',
        icon: [
            {
                type: 'default',
                svg: `<path class="IconicIcon" d="M6.37,18a.72.72,0,0,1-.52-.21.76.76,0,0,1,0-1.05L12.58,10,5.85,3.27a.76.76,0,0,1,0-1,.74.74,0,0,1,1.05,0l7.27,7.27a.75.75,0,0,1,.19.51.69.69,0,0,1-.21.52L6.9,17.79A.79.79,0,0,1,6.37,18Z"/>`
            }
        ]
    } as IconicIcon,
    ArrowLeft: {
        name: 'Arrow Left',
        icon: [
            {
                type: 'default',
                svg: `<path class="IconicIcon" d="M13.63,2a.72.72,0,0,1,.52.21.76.76,0,0,1,0,1L7.42,10l6.73,6.73a.76.76,0,0,1,0,1.05.74.74,0,0,1-1.05,0L5.83,10.51A.75.75,0,0,1,5.64,10a.69.69,0,0,1,.21-.52L13.1,2.21A.79.79,0,0,1,13.63,2Z"/>`
            }
        ]
    } as IconicIcon,
    ArrowUp: {
        name: 'Arrow Up',
        icon: [
            {
                type: 'default',
                svg: `<path class="IconicIcon" d="M18,13.63a.72.72,0,0,1-.21.52.76.76,0,0,1-1.05,0L10,7.42,3.27,14.15a.76.76,0,0,1-1,0,.74.74,0,0,1,0-1.05L9.49,5.83A.75.75,0,0,1,10,5.64a.69.69,0,0,1,.52.21l7.27,7.25A.79.79,0,0,1,18,13.63Z"/>`
            }
        ]
    } as IconicIcon,
    ArrowLongDown: {
        name: 'Arrow Long Down',
        icon: [
            {
                type: 'default',
                svg: `<path class="IconicIcon" d="M4.48,12.4l5.1,4.44A.62.62,0,0,0,10,17h0a.59.59,0,0,0,.32-.11h0a.4.4,0,0,0,.12-.1h0l5-4.38a.62.62,0,0,0-.41-1.09.63.63,0,0,0-.41.15L10.63,15V3.62a.63.63,0,0,0-1.25,0V15L5.3,11.46a.63.63,0,0,0-.88.06A.62.62,0,0,0,4.48,12.4Z"/>`
            }
        ]
    } as IconicIcon,
    ArrowLongRight: {
        name: 'Arrow Long Right',
        icon: [
            {
                type: 'default',
                svg: `<path class="IconicIcon" d="M12.4,15.52l4.44-5.1A.62.62,0,0,0,17,10h0a.59.59,0,0,0-.11-.32h0a.4.4,0,0,0-.1-.12h0l-4.38-5a.62.62,0,0,0-1.09.41.63.63,0,0,0,.15.41L15,9.37H3.62a.63.63,0,0,0,0,1.25H15L11.46,14.7a.63.63,0,0,0,.06.88A.62.62,0,0,0,12.4,15.52Z"/>`
            }
        ]
    } as IconicIcon,
    ArrowLongLeft: {
        name: 'Arrow Long Left',
        icon: [
            {
                type: 'default',
                svg: `<path class="IconicIcon" d="M7.6,4.48,3.16,9.58A.62.62,0,0,0,3,10H3a.59.59,0,0,0,.11.32h0a.4.4,0,0,0,.1.12h0l4.38,5a.62.62,0,0,0,1.09-.41.63.63,0,0,0-.15-.41L5,10.63H16.38a.63.63,0,0,0,0-1.25H5L8.54,5.3a.63.63,0,0,0-.06-.88A.62.62,0,0,0,7.6,4.48Z"/>`
            }
        ]
    } as IconicIcon,
    ArrowLongUp: {
        name: 'Arrow Long Up',
        icon: [
            {
                type: 'default',
                svg: `<path class="IconicIcon" d="M15.52,7.6l-5.1-4.44A.62.62,0,0,0,10,3h0a.59.59,0,0,0-.32.11h0a.4.4,0,0,0-.12.1h0l-5,4.38a.62.62,0,0,0,.41,1.09.63.63,0,0,0,.41-.15L9.37,5V16.38a.63.63,0,0,0,1.25,0V5L14.7,8.54a.63.63,0,0,0,.88-.06A.62.62,0,0,0,15.52,7.6Z"/>`
            }
        ]
    } as IconicIcon,
    Account: {
        name: 'Account',
        icon: [
            {
                type: 'default',
                svg: `  <g>
                            <path class="IconicIcon" d="M10,10.11a3.78,3.78,0,0,0,3.6-3.93A3.77,3.77,0,0,0,10,2.26,3.78,3.78,0,0,0,6.4,6.19,3.77,3.77,0,0,0,10,10.11Zm0-7a3,3,0,0,1,2.77,3.1A2.94,2.94,0,0,1,10,9.28a3,3,0,0,1-2.77-3.1A2.94,2.94,0,0,1,10,3.09Z"/>
                            <path class="IconicIcon" d="M14.37,9a.41.41,0,0,0-.58.09.42.42,0,0,0,.09.58c1.51,1.09,2.5,2,2.5,4v1a2.46,2.46,0,0,1-2.59,2.26H5.88a2.16,2.16,0,0,1-2.26-2.26v-1c0-2,1-2.87,2.49-4a.41.41,0,0,0,.09-.58A.42.42,0,0,0,5.62,9C4,10.19,2.79,11.27,2.79,13.65v1a3,3,0,0,0,3.09,3.09H13.8a3.27,3.27,0,0,0,3.42-3.09v-1C17.21,11.25,16,10.17,14.37,9Z"/>
                        </g>`
            }
        ]
    } as IconicIcon,
    Lock: {
        name: 'Lock',
        icon: [
            {
                type: 'default',
                svg: `  <g>
                            <path class="IconicIcon" d="M15.59,8.06H14.5V6.5a4.5,4.5,0,0,0-9,0V8.06H4.41A1.39,1.39,0,0,0,3,9.46V16.6A1.39,1.39,0,0,0,4.41,18H15.59A1.39,1.39,0,0,0,17,16.6V9.46A1.39,1.39,0,0,0,15.59,8.06Z"/>
                            <path class="IconicIcon" d="M10.31,12.72V10.54a.31.31,0,1,0-.62,0v2.18A1.32,1.32,0,0,0,8.6,14a1.4,1.4,0,1,0,2.8,0A1.32,1.32,0,0,0,10.31,12.72Z"/>
                            <line class="IconicIcon" x1="5.5" y1="8.06" x2="14.5" y2="8.06"/>
                        </g>`
            }
        ]
    } as IconicIcon,
    Save: {
        name: 'Save',
        icon: [
            {
                type: 'default',
                svg: `<path class="IconicIcon" d="M17,6,14,3a.4.4,0,0,0-.29-.12H4.05A1.12,1.12,0,0,0,2.93,4V16a1.12,1.12,0,0,0,1.12,1.12H16A1.12,1.12,0,0,0,17.07,16V6.24A.36.36,0,0,0,17,6ZM14.18,4.36V6.72a.39.39,0,0,1-.38.38H6.2a.39.39,0,0,1-.38-.38V3.82s0,0,0-.06h6.58V5.89a.42.42,0,1,0,.84,0V3.76h.35Zm0,11.88H5.83V11.29a1,1,0,0,1,1-1h6.28a1,1,0,0,1,1,1ZM16.24,16a.28.28,0,0,1-.28.28H15V11.29a1.87,1.87,0,0,0-1.88-1.86H6.86A1.87,1.87,0,0,0,5,11.29v4.95H4.05A.28.28,0,0,1,3.77,16V4a.28.28,0,0,1,.28-.28H5s0,0,0,.06V6.71A1.23,1.23,0,0,0,6.21,7.93h7.6A1.23,1.23,0,0,0,15,6.71V5.2l1.22,1.22V16Z"/>`
            }
        ]
    } as IconicIcon,
    Dribbble: {
        name: 'Dribbble',
        icon: [
            {
                type: 'default',
                svg: `<path class="IconicIcon" d="M0,10A9.83,9.83,0,0,1,1.34,5,9.93,9.93,0,0,1,5,1.34,9.83,9.83,0,0,1,10,0a9.83,9.83,0,0,1,5,1.34A9.93,9.93,0,0,1,18.66,5,9.83,9.83,0,0,1,20,10a9.83,9.83,0,0,1-1.34,5A9.93,9.93,0,0,1,15,18.66,9.83,9.83,0,0,1,10,20a9.83,9.83,0,0,1-5-1.34A9.93,9.93,0,0,1,1.34,15,9.83,9.83,0,0,1,0,10Zm1.66,0a8.07,8.07,0,0,0,2.1,5.5,11.4,11.4,0,0,1,3-3.58,10,10,0,0,1,4.06-2.14c-.2-.47-.39-.89-.58-1.26a24.37,24.37,0,0,1-7.44,1.1l-1.16,0a1.56,1.56,0,0,1,0,.2A1.56,1.56,0,0,0,1.66,10Zm.26-2.06c.29,0,.73,0,1.3,0a21.93,21.93,0,0,0,6.34-.9,20.25,20.25,0,0,0-3.34-4.5A8,8,0,0,0,3.51,4.8,8.64,8.64,0,0,0,1.92,7.94Zm3,8.64a8.17,8.17,0,0,0,8,1.2,29.69,29.69,0,0,0-1.56-6.62,8.56,8.56,0,0,0-3.71,2A11.25,11.25,0,0,0,4.9,16.58ZM8,1.94a21.37,21.37,0,0,1,3.26,4.54,10,10,0,0,0,4.1-2.9A8.11,8.11,0,0,0,10,1.66,7.54,7.54,0,0,0,8,1.94Zm4,6c.2.43.43,1,.68,1.62,1-.09,2.06-.14,3.22-.14q1.25,0,2.46.06a8,8,0,0,0-2-4.84A9.94,9.94,0,0,1,11.94,7.9Zm1.18,3A28.66,28.66,0,0,1,14.5,17a8.38,8.38,0,0,0,2.58-2.62,8,8,0,0,0,1.2-3.46c-1-.07-1.86-.1-2.66-.1S14.05,10.85,13.12,10.92Z"/>`
            }
        ]
    } as IconicIcon,
    Facebook: {
        name: 'Facebook',
        icon: [
            {
                type: 'default',
                svg: `<path class="IconicIcon" d="M15,1.13v3H13.36a5.52,5.52,0,0,0-.58,0l-.39.09a1.19,1.19,0,0,0-.46.27,1.13,1.13,0,0,0-.3.52,2.48,2.48,0,0,0-.08.66V8h3.33s-.41,3.32-.43,3.37h-2.9V20h0s-3.43,0-3.47,0V11.33H5.15V8H8.07V5.51c0-.15,0-.3,0-.45a5.35,5.35,0,0,1,.08-.7,5,5,0,0,1,.33-1.08,3.9,3.9,0,0,1,1-1.34,4.12,4.12,0,0,1,2.07-.88l.3,0,.32,0c.39,0,.78,0,1.17,0h.4l.5,0h.12l.35,0Z" transform="translate(0 -1)"/>`
            }
        ]
    } as IconicIcon,
    Instagram: {
        name: 'Instagram',
        icon: [
            {
                type: 'default',
                svg: `  <path class="IconicIcon" d="M19.94,6.09a8.3,8.3,0,0,0-.26-1.92A5.07,5.07,0,0,0,16,.33,8.25,8.25,0,0,0,13.7,0C11.55,0,9.41,0,7.27,0A12.84,12.84,0,0,0,4.5.21,5.22,5.22,0,0,0,.3,4.12a7.34,7.34,0,0,0-.25,2c0,2.12,0,4.24,0,6.36A19.72,19.72,0,0,0,.2,15.28a5.25,5.25,0,0,0,2,3.51,6,6,0,0,0,3.41,1.12C7.09,20,8.54,20,10,20v0c.65,0,1.3,0,1.95,0a16.91,16.91,0,0,0,3.48-.2,5.21,5.21,0,0,0,4.32-4.07A13.68,13.68,0,0,0,20,12.44C20,10.33,20,8.21,19.94,6.09Zm-1.73,6.17A22,22,0,0,1,18,15.07,3.49,3.49,0,0,1,15.09,18a17.12,17.12,0,0,1-2.86.2c-1.68,0-3.35,0-5,0A16.26,16.26,0,0,1,4.89,18a3.52,3.52,0,0,1-2.78-2.42,17.48,17.48,0,0,1-.35-4.27c0-.47,0-.94,0-1.41A36.91,36.91,0,0,1,2,4.76,3.7,3.7,0,0,1,4.88,2a29,29,0,0,1,4.66-.21l3.84.05A10.69,10.69,0,0,1,15.33,2,3.45,3.45,0,0,1,18,5a23.54,23.54,0,0,1,.19,2.52C18.24,9.07,18.25,10.67,18.21,12.26Z" transform="translate(-0.01 0)"/>
                        <path class="IconicIcon" d="M10,4.84A5.15,5.15,0,1,0,15.09,10,5.1,5.1,0,0,0,10,4.84ZM9.94,13.3A3.34,3.34,0,1,1,13.31,10,3.32,3.32,0,0,1,9.94,13.3Z" transform="translate(-0.01 0)"/>
                        <path class="IconicIcon" d="M15.32,3.4a1.26,1.26,0,0,0-1.2,1.22,1.25,1.25,0,0,0,1.2,1.2,1.27,1.27,0,0,0,1.22-1.21A1.27,1.27,0,0,0,15.32,3.4Z" transform="translate(-0.01 0)"/>
            
                
                `
            }
        ]
    } as IconicIcon
};
