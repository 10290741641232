import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactPageComponent } from './contact.page';
import { FooterModule } from 'src/app/components/blocks/footer/footer.module';
import { SimpleBlockModule } from 'src/app/components/blocks/simple-block/simple-block.module';
import { HeroTextModule } from 'src/app/components/blocks/hero-text/hero-text.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { ActionButtonModule } from 'src/app/components/action-button/action-button.module';



@NgModule({
  declarations: [ContactPageComponent],
  imports: [
    CommonModule,
    SharedModule,
    FooterModule,
    HeroTextModule,
    SimpleBlockModule,
    ActionButtonModule
  ],
  exports: [ContactPageComponent],
})
export class ContactPageModule { }
