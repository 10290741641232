import { Component, OnInit, Input } from '@angular/core';
import { NavService } from 'src/app/services/nav.service';

@Component({
  selector: 'app-contactpage',
  templateUrl: './contact.page.html',
  styleUrls: ['./contact.page.scss']
})
export class ContactPageComponent implements OnInit {

  public formName = '';
  public formEmail = '';
  public formMsg = '';

  public isSending = false;
  public sendStatus = 0;
  public returnReason = '';

  constructor(
    private navService: NavService
  ) { }

  ngOnInit() {
  }

  public click_Send() {
    this.isSending = true;

    this.navService.sendEmail(this.formEmail, this.formName, this.formMsg).subscribe((res) => {
      this.isSending = false;
      this.sendStatus = 1;
    },
    (error) => {
      this.returnReason = error.error.text || 'Unknown Error.';
      this.isSending = false;
      this.sendStatus = 2;

      setTimeout(() => {
        this.sendStatus = 0;
      }, 2000);
    });
  }

}
