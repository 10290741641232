import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TermsPageComponent } from './terms.page';
import { FooterModule } from 'src/app/components/blocks/footer/footer.module';
import { SimpleBlockModule } from 'src/app/components/blocks/simple-block/simple-block.module';
import { HeroTextModule } from 'src/app/components/blocks/hero-text/hero-text.module';
import { SharedModule } from 'src/app/shared/shared.module';



@NgModule({
  declarations: [TermsPageComponent],
  imports: [
    CommonModule,
    SharedModule,
    FooterModule,
    HeroTextModule,
    SimpleBlockModule
  ],
  exports: [TermsPageComponent],
})
export class TermsPageModule { }
