import { Directive, ElementRef, EventEmitter, HostListener, Output, AfterViewInit, OnDestroy, Input } from '@angular/core';

/**
 * This directive should be used with components
 * where you want to perform some action by clicking
 * outside e.g: closing custom popover or modal box by clicking outside
 */

@Directive({
    selector: '[visibleFade]'
})
export class VisibleFadeDirective implements AfterViewInit, OnDestroy {
    @Input() visibleFade: number;
    private canEnable = false;
    private saidVisible = false;
    constructor(private elementRef: ElementRef) {
    }

    ngAfterViewInit() {
        this.elementRef.nativeElement.classList.add('o-not-visible');
        document.querySelector('#pageScroller').addEventListener('scroll', () => { this.onWindowScroll(); });

        setTimeout(() => {
            this.canEnable = true;

            if (this.checkVisible(this.elementRef)) {
                this.setVisible();
            }
        }, 250);
    }

    ngOnDestroy() {
        document.querySelector('#pageScroller').removeEventListener('scroll', () => { this.onWindowScroll(); });
    }

    public onWindowScroll() {
        if (this.checkVisible(this.elementRef)) {
            this.setVisible();
        }
    }

    private checkVisible(elementRef: ElementRef) {
        const eleBounds: DOMRect = this.elementRef.nativeElement.getBoundingClientRect();
        if (eleBounds.top < (window.innerHeight - 50) && // (window.innerHeight * 0.15)
            this.elementRef.nativeElement.offsetParent) {
            return true;
        } else {
            return false;
        }
    }

    private setVisible() {
        if (!this.saidVisible && this.canEnable) {
            document.querySelector('#pageScroller').removeEventListener('scroll', () => { this.onWindowScroll(); });
            this.saidVisible = true;
            setTimeout(() => {
                this.elementRef.nativeElement.classList.remove('o-not-visible');
                this.elementRef.nativeElement.classList.add('o-visible');
            }, this.visibleFade ? this.visibleFade : 0);
        }
    }
}
