import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-hero-text',
  templateUrl: './hero-text.component.html',
  styleUrls: ['./hero-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeroTextComponent {

    @Input() main = '';
    @Input() sub = '';
    @Input() cta = '';
    @Input() ctaLink = '';

}
