import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SimpleBlockComponent } from './simple-block.component';
import { SharedModule } from 'src/app/shared/shared.module';



@NgModule({
  declarations: [SimpleBlockComponent],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [SimpleBlockComponent],
})
export class SimpleBlockModule { }
