import { Component, OnInit, Input, ChangeDetectionStrategy, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-side-image',
  templateUrl: './side-image.component.html',
  styleUrls: ['./side-image.component.scss']
})
export class SideImageComponent implements OnInit, OnDestroy {

  @Input() main = '';
  @Input() sub = '';
  @Input() cta = '';
  @Input() ctaLink = '';
  @Input() flipped = false;
  @Input() align = true;
  @Input() asset = '';
  @Input() background = '';
  @Input() backgroundPop: string[] = [];
  @Input() speed = 7000;

  private intervalID = null;
  public currentImage = 0;

  constructor() { }

  ngOnInit() {
    this.intervalID = setInterval(() => this.nextImage(), this.speed);
  }

  ngOnDestroy() {
    clearInterval(this.intervalID);
  }

  nextImage() {
    this.currentImage ++;
    if (this.currentImage >= this.backgroundPop.length) {
      this.currentImage = 0;
    }
  }

}
