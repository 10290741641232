import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActionButtonComponent } from './action-button.component';
import { IconicModule } from '../iconic/iconic.module';



@NgModule({
  declarations: [
    ActionButtonComponent
  ],
  imports: [
    CommonModule,
    IconicModule
  ],
  exports: [
    ActionButtonComponent
  ]
})
export class ActionButtonModule { }
