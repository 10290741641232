import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer.component';
import { VersionMaybeModule } from '../../versionmaybe/versionmaybe.module';
import { IconicModule } from '../../iconic/iconic.module';
import { RouterModule } from '@angular/router';
import { ActionButtonModule } from '../../action-button/action-button.module';
import { SharedModule } from 'src/app/shared/shared.module';



@NgModule({
  declarations: [
    FooterComponent
  ],
  imports: [
    CommonModule,
    VersionMaybeModule,
    IconicModule,
    RouterModule,
    ActionButtonModule,
    SharedModule
  ],
  exports: [
    FooterComponent
  ]
})
export class FooterModule { }
