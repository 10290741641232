import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VersionmaybeComponent } from './versionmaybe.component';



@NgModule({
  declarations: [
    VersionmaybeComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    VersionmaybeComponent,
  ],
})
export class VersionMaybeModule { }
