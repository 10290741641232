import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IsVisibleDirective } from '../directives/is-visible.directive';
import { VisibleFadeDirective } from '../directives/visible-fade.directive';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
  ],
  declarations: [
    IsVisibleDirective,
    VisibleFadeDirective
  ],
  exports: [
    IsVisibleDirective,
    VisibleFadeDirective,
    FormsModule
  ],
})
export class SharedModule { }
