import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-terms-page',
  templateUrl: './terms.page.html',
  styleUrls: ['./terms.page.scss']
})
export class TermsPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
