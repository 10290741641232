import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

/**
 * This directive should be used with components
 * where you want to perform some action by clicking
 * outside e.g: closing custom popover or modal box by clicking outside
 */

@Directive({
    selector: '[isVisible]'
})
export class IsVisibleDirective {
    @Output() public isVisible = new EventEmitter();
    private saidVisible = false;

    constructor(private elementRef: ElementRef) {}


    @HostListener('window:scroll', [])
    public onWindowScroll() {
        const eleBounds: DOMRect = this.elementRef.nativeElement.getBoundingClientRect();
        if (
            eleBounds.top >= 0 && 
            eleBounds.left >= 0 && 
            eleBounds.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            eleBounds.right <= (window.innerWidth || document.documentElement.clientWidth)) {
            if (!this.saidVisible) {
                this.saidVisible = true;
                this.isVisible.emit();
            }
        }
    }
}
